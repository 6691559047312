<app-menu></app-menu>
<div id="tools-page" class="container-fluid">
    <div class="search">
        <ul id="lang-filters">
            <li [ngClass]="{'active': selectedLang == lang}" *ngFor="let lang of languages"
                (click)="onLangUpdate(lang)">{{lang}}
            </li>
        </ul>
        <app-alphabetical [defaultLetter]="selectedLetter" (notify)="onLetterFilterUpdate($event)"></app-alphabetical>
        <div class="search-term">
            <mat-form-field appearance="outline">
                <mat-label>Rechercher</mat-label>
                <input matInput placeholder="search term" autocomplete="off"
                       #searchInput>
                <mat-hint>La recherche se fait dans toute la base sans distinction de langue</mat-hint>
            </mat-form-field>
            <span class="separator"></span>
            <button mat-raised-button (click)="search(searchInput.value)">search</button>
        </div>
    </div>

    <div id="result">
        <div>
            <mat-form-field appearance="outline">
                <mat-label>Filtrer la liste</mat-label>
                <input matInput placeholder="filter the list" (keyup)="filter(filterInput.value)" autocomplete="off"
                       #filterInput>
            </mat-form-field>
            <div class="count" *ngIf="!loading">
                {{ tools.length }} tools found
            </div>
        </div>

        <ul id="tools-list" [ngClass]="{'bggray': tools.length > 0}">
            <span *ngIf="loading" class="loader">
                <i class="fal fa-spinner fa-spin"></i>
                <span>loading...</span>
            </span>
            <mat-card class="tool" *ngFor="let tool of tools" (click)="editKeywords(tool)">
                <div class="left">
                    <img src="{{ tool.getToolsIcon() }}" alt="#pic">
                    <span class="tool-id"> {{ tool.id }}</span>
                    <span class="name"> {{ tool.name }} </span>
                </div>
                <div class="right">
                    <span [ngClass]="{'rules-count': true, 'green': tool.nb_rules > 0}">{{ tool.nb_rules }} rule(s)</span>
                    <a href="{{ tool.source }}" target="_blank">
                        <i class="fal fa-external-link-alt"></i>
                    </a>
                </div>
            </mat-card>
        </ul>
    </div>

</div>
