import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, ActivatedRouteSnapshot } from '@angular/router';
import { HistoryService } from 'src/app/services/history.service';
import { HistoryEntry } from 'src/app/model/history-entry.model';

@Component({
    selector: 'app-db-log',
    templateUrl: './db-log.component.html',
    styleUrls: ['./db-log.component.scss']
})
export class DbLogComponent implements OnInit {
    uuid: string
    load: boolean
    historyEntry: HistoryEntry

    constructor(private historyService: HistoryService, private activatedRoute: ActivatedRoute) {
        this.uuid = this.activatedRoute.snapshot.params.uuid
        this.historyService.historyUUID = this.uuid
        this.load = false
        this.historyEntry = null
    }

    ngOnInit() {
        this.historyService.getHistoryEntry(this.uuid)
            .subscribe(entry => {
                this.historyEntry = new HistoryEntry(entry);
                this.load = true
            })
    }

}
