import { Injectable } from '@angular/core';
import { Resolve, RouterStateSnapshot, ActivatedRouteSnapshot } from '@angular/router';
import { HistoryService } from '../services/history.service';
import { Observable } from 'rxjs';
import { HistoryEntry } from '../model/history-entry.model';
import { LIMIT_VAL_FOR } from '../services/static/limit';

@Injectable({ providedIn: 'root' })
export class HistoryResolver implements Resolve<any> {
    constructor(private historyService: HistoryService) { }

    resolve(): Observable<HistoryEntry[]> {
        return this.historyService.getHistory(0, LIMIT_VAL_FOR.HISTORY)
    }
}