import { Injectable } from '@angular/core';
import { HistoryEntry } from '../model/history-entry.model';
import { ApiService } from './http/api.service';
import { TocLog } from '../model/toc-log.model';
import { DrugLog } from '../model/drug-log.model';
import { Observable } from 'rxjs';
import { LoggerService } from './logger.service';
import { AdminService } from './admin.service';
import {ChartDataInterface} from '../model/model';

@Injectable({
    providedIn: 'root'
})
export class HistoryService {
    // this value is update when user click on history entry
    history: HistoryEntry
    historyUUID: string
    execptionsLocalStorageKey = "drugpdfexceptions"

    constructor(private api: ApiService, private logger: LoggerService, private adminService: AdminService) {
        this.history = null
        this.historyUUID = null
    }

    getHistory(offset: number = 0, limit: number = 10): Observable<HistoryEntry[]> {
        return this.api.get('/logs/history', { offset, limit })
    }

    getHistoryEntry(uuid: string): Observable<HistoryEntry> {
        return this.api.get('/fetch/history-entry', { uuid })
    }

    getTocs(offset: number = 0, limit: number = 10, success: boolean = true): Observable<TocLog[]> {
        return this.api.get('/logs/toc', { uuid: this.historyUUID, offset, limit, success })
    }

    getDrugs(offset: number = 0, limit: number = 10, success: boolean = true): Observable<DrugLog[]> {
        return this.api.get('/logs/drug', { uuid: this.historyUUID, offset, limit, success })
    }

    getChartsData(): Observable<ChartDataInterface> {
        return this.api.get('/logs/charts', { uuid: this.historyUUID })
    }

    removeHistory(uuid: string) {
        return this.api.delete('/logs/history/delete', { uuid: uuid })
    }

    loadFromFirestore() {
        return !this.history.processDone() && this.history.processDurationLowerThanHour(2)
    }

    notifyException(value: string, expected: string): Observable<any> {
        return this.api.post('/pdf/exception', { value, expected })
    }

    async reprocessErroredDrugs(database: string, logHistoryUUID: string): Promise<any> {
        const cisList = await this.api.get('/reprocess-errored-drugs', { uuid: logHistoryUUID }).toPromise()
        return this.adminService.update(database, cisList).toPromise()
    }

    markExeptionAsTreated(id: string) {
        const key = this.execptionsLocalStorageKey
        let ids = localStorage.getItem(key) != undefined ? localStorage.getItem(key)+"," :  ""
        ids += id // 3,345,456...

        localStorage.setItem(key, ids)
    }

    listExceptionsMarkAsTreated(): string[]{
        const list = localStorage.getItem(this.execptionsLocalStorageKey)
        if (list == null) {
            return []
        }
        return list.split(",")
    }

    getNumberOfTechnicalFileViewable(uuid: string): Observable<{ number_of_files_viewable: number, total_files_processed: number}> {
        return this.api.get(`/logs/${uuid}/technical-files-viewable-count`)
    }

    getNumberOfFileNotViewableGroupByDoctype(uuid: string): Observable<any>{
        return this.api.get(`/logs/${uuid}/technical-files-not-viewable`)
    }

    getListOfFileGroupByNbTocsAndDoctype(uuid: string): Observable<any> {
        return this.api.get(`/logs/${uuid}/files-grouped-by-tocs-and-doctype`)
    }
}
