<h2>Tocs Logs </h2>
<div class="sub">
    <button (click)="refresh()" mat-mini-fab color="default">
        <i class="fal fa-sync"></i>
    </button>
    <div class="excpt">
        Your session have treated <strong>{{ nbExceptionsTreatedByThisSession }}</strong> exception(s) !
    </div>
</div>
<mat-tab-group animationDuration="500ms">
    <mat-tab label="List">
        <ul #scrollBottom>
            <app-toc-monitoring-entry [toc]="toc" [index]="tocs.length - i" *ngFor="let toc of tocs; index as i">
            </app-toc-monitoring-entry>

            <a mat-stroked-button (click)="loadMoreTocs()" *ngIf="tocs.length >= limit">
                Load more
            </a>

            <div t-a-c w-100>
                <span *ngIf="tocsLoading">
                    <i class="fal fa-spinner fa-spin"></i>
                </span>
                <p *ngIf="tocs.length === 0 && !tocsLoading">
                    No drugs found
                </p>
            </div>
        </ul>
    </mat-tab>
    <mat-tab label="{{ 'Errors [' + tocsErrors.length + ']' }}">
        <ul #scrollBottom>
            <app-toc-monitoring-entry [toc]="toc" [markExceptionAsTreated]="isExceptionTreated(toc.id)" [index]="tocsErrors.length - i"
                *ngFor="let toc of tocsErrors; index as i">
            </app-toc-monitoring-entry>

            <a mat-stroked-button (click)="loadMoreTocsErrors()"
                *ngIf="tocsErrors.length >= limit">
                Load more
            </a>

            <div t-a-c w-100>
                <span *ngIf="tocsErrorLoading">
                    <i class="fal fa-spinner fa-spin"></i>
                </span>
                <p *ngIf="tocsErrors.length === 0 && !tocsErrorLoading">
                    No error found
                </p>
            </div>
        </ul>
    </mat-tab>
</mat-tab-group>
