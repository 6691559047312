import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { DbLogsComponent } from './pages/db-logs/db-logs.component';
import { HistoryResolver } from './resolvers/history.resolver';
import { DbLogComponent } from './pages/db-log/db-log.component';
import { LoginComponent } from './pages/login/login.component';
import { AuthGuard } from './security/auth.guard';
import { ToolsComponent } from './pages/tools/tools.component';


const routes: Routes = [
    {
        path: '',
        redirectTo: '/db-logs',
        pathMatch: 'full'
    },
    {
        path: 'login',
        component: LoginComponent
    },
    {
        path: 'db-logs',
        canActivate: [AuthGuard],
        children: [
            {
                path: '',
                component: DbLogsComponent,
                resolve: {
                    history: HistoryResolver
                }
            },
            {
                path: ':uuid/detail',
                component:  DbLogComponent,
            }
        ]
    },
    {
        path: 'tools',
        canActivate: [AuthGuard],
        children: [
            {
                path: '',
                component: ToolsComponent
            }
        ]
    },
    { path: '**', redirectTo: '' }
];

@NgModule({
    imports: [RouterModule.forRoot(routes)],
    exports: [RouterModule]
})
export class AppRoutingModule { }
