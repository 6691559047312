import {BrowserModule} from '@angular/platform-browser';
import {NgModule} from '@angular/core';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';

import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {HttpClientModule} from '@angular/common/http';

import {AngularFireModule} from '@angular/fire';
import {AngularFireDatabaseModule} from '@angular/fire/database';

import {MatExpansionModule} from '@angular/material/expansion';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatTableModule} from '@angular/material/table';
import {MatTabsModule} from '@angular/material/tabs';
import {MatSidenavModule} from '@angular/material/sidenav';
import {MatButtonModule} from '@angular/material/button';
import {MatSelectModule} from '@angular/material/select';
import {MatInputModule} from '@angular/material/input';
import {MatCardModule} from '@angular/material/card';
import {MatProgressBarModule} from '@angular/material/progress-bar';
import {MatAutocompleteModule} from '@angular/material/autocomplete';
import {MatDialogModule} from '@angular/material/dialog';
import {MatListModule} from '@angular/material/list';
import {MatSlideToggleModule} from '@angular/material/slide-toggle';
import {MatMenuModule} from '@angular/material/menu';
import {MatChipsModule} from '@angular/material/chips';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {MatIconModule} from '@angular/material/icon';
import {MatSnackBarModule} from '@angular/material/snack-bar';


import {HistoryComponent} from './components/history/history.component';
import {HistoryEntryComponent} from './components/history-entry/history-entry.component';
import {MonitoringComponent} from './components/monitoring/monitoring.component';
import {TocsMonitoringComponent} from './components/tocs-monitoring/tocs-monitoring.component';
import {DrugsMonitoringComponent} from './components/drugs-monitoring/drugs-monitoring.component';
import {TocMonitoringEntryComponent} from './components/toc-monitoring-entry/toc-monitoring-entry.component';
import {DrugMonitoringEntryComponent} from './components/drug-monitoring-entry/drug-monitoring-entry.component';
import {AdminPanelComponent} from './components/admin-panel/admin-panel.component';
import {environment} from 'src/environments/environment';
import {AngularFirestore, AngularFirestoreModule} from '@angular/fire/firestore';
import {MonitoringChartComponent} from './components/monitoring-chart/monitoring-chart.component';

import {ChartsModule} from 'ng2-charts';
import {DbLogsComponent} from './pages/db-logs/db-logs.component';
import {DoctypeChartComponent} from './components/chart/doctype-chart/doctype-chart.component';
import {ErrorChartComponent} from './components/chart/error-chart/error-chart.component';
import {DrugsStatusChartComponent} from './components/chart/drugs-status-chart/drugs-status-chart.component';
import {TocsCountChartComponent} from './components/chart/tocs-count-chart/tocs-count-chart.component';
import {DbLogComponent} from './pages/db-log/db-log.component';
import {TocExceptionComponent} from './modal/toc-exception/toc-exception.component';
import {TocsExplicitErrorComponent} from './components/chart/tocs-explicit-error/tocs-explicit-error.component';
import {ChipsComponent} from './components/chips/chips.component';
import {LoginComponent} from './pages/login/login.component';
import {MenuComponent} from './components/menu/menu.component';
import {AngularFireAuth} from '@angular/fire/auth';
import {ToolsComponent} from './pages/tools/tools.component';
import {ToolEditComponent} from './pages/tool-edit/tool-edit.component';
import {ConfirmationDialogComponent} from './components/confirmation-dialog/confirmation-dialog.component';
import {AlphabeticalComponent} from './components/alphabetical/alphabetical.component';

@NgModule({
    declarations: [
        AppComponent,
        HistoryComponent,
        HistoryEntryComponent,
        MonitoringComponent,
        TocsMonitoringComponent,
        DrugsMonitoringComponent,
        TocMonitoringEntryComponent,
        DrugMonitoringEntryComponent,
        AdminPanelComponent,
        MonitoringChartComponent,
        DbLogsComponent,
        DoctypeChartComponent,
        ErrorChartComponent,
        DrugsStatusChartComponent,
        TocsCountChartComponent,
        DbLogComponent,
        TocExceptionComponent,
        TocsExplicitErrorComponent,
        ChipsComponent,
        LoginComponent,
        MenuComponent,
        ToolsComponent,
        ToolEditComponent,
        ConfirmationDialogComponent,
        AlphabeticalComponent,
    ],
    entryComponents: [
        TocExceptionComponent,
        ToolEditComponent,
        ConfirmationDialogComponent
    ],
    imports: [
        BrowserModule,
        HttpClientModule,
        AppRoutingModule,
        BrowserAnimationsModule,

        AngularFireModule.initializeApp(environment.firebaseConfig),
        AngularFirestoreModule,
        AngularFireDatabaseModule,

        MatExpansionModule,
        MatFormFieldModule,
        MatTableModule,
        MatTabsModule,
        MatSidenavModule,
        MatButtonModule,
        MatSelectModule,
        MatInputModule,
        MatCardModule,
        MatProgressBarModule,
        MatAutocompleteModule,
        MatListModule,
        MatDialogModule,
        MatSlideToggleModule,
        MatMenuModule,
        MatCheckboxModule,
        MatChipsModule,
        MatIconModule,
        MatSnackBarModule,

        FormsModule,
        ReactiveFormsModule,

        ChartsModule
    ],
    providers: [AngularFirestore, AngularFireAuth],
    bootstrap: [AppComponent]
})
export class AppModule {
}
