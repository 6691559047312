import { Component, OnInit, Input } from '@angular/core';
import { ChartOptions, ChartType } from 'chart.js';
import { Label } from 'ng2-charts';
import { COLOR } from 'src/app/services/static/color';
import {TocsErrorChart} from '../../../model/model';

@Component({
    selector: 'app-error-chart',
    templateUrl: './error-chart.component.html',
    styleUrls: ['./error-chart.component.scss']
})
export class ErrorChartComponent implements OnInit {

    @Input() data: TocsErrorChart[] = []

    pieChartLabels: Label[] = [];
    pieChartData: number[] = [];

    pieChartType: ChartType = 'doughnut';
    pieChartLegend = true;

    pieChartOptions: ChartOptions = {
        responsive: true,
        legend: {
            position: 'right',
        },
        plugins: {
            datalabels: {
                formatter: (value, ctx) => {
                    const label = ctx.chart.data.labels[ctx.dataIndex];
                    return label;
                },
            },
        },
        title: {
            display: true,
            position: 'top',
            text: 'Tocs errors by error type'
        }
    };

    // pieChartPlugins = [pluginDataLabels];
    pieChartColors = [
        {
            backgroundColor: [
                COLOR.RED,
                COLOR.WARNING,
                COLOR.GRAY
            ],
        },
    ];

    constructor() { }

    ngOnInit() {
        this.data.forEach((entry: TocsErrorChart) => {
            this.pieChartData.push(parseInt(entry.count))
            this.pieChartLabels.push(entry.error_type)
        })
    }
}
