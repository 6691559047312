import { Component, OnInit, Input} from '@angular/core';
import { HistoryService } from 'src/app/services/history.service';
import { MonitoringService } from 'src/app/services/monitoring.service';
import { HistoryEntry } from 'src/app/model/history-entry.model';
import { LoggerService } from 'src/app/services/logger.service';

@Component({
    selector: 'app-monitoring',
    templateUrl: './monitoring.component.html',
    styleUrls: ['./monitoring.component.scss']
})
export class MonitoringComponent implements OnInit {

    @Input() load: boolean
    @Input() historyEntry: HistoryEntry

    constructor(public monitoringService: MonitoringService, private historyService: HistoryService, private logger: LoggerService) {
        this.load = true
    }

    ngOnInit() {
        this.historyService.history = this.historyEntry
    }

    scrollToElement(element: any) {
        element.scrollIntoView({ behavior: "smooth", block: "start", inline: "nearest" })
    }

    reProcessedErroredDrugs() {
        this.historyService.reprocessErroredDrugs(this.historyEntry.db_name, this.historyEntry.uuid)
            .then(resp => this.logger.log(resp))
            .catch((err) => this.logger.error(err))
    }
}
