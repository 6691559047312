import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class MonitoringService {

  drugsError: any[] = []
  tocsError: any[] = []

  lastTocError: string
  lastDrugError: string

  constructor() {
    this.lastDrugError = null
    this.lastTocError = null
  }

}
