import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class LoggerService {

  constructor() { }

  error(msg?: any, ...optionalParams: any[]) {
    if (!environment.production) {
      console.error(msg, optionalParams)
    }
  }

  log(msg?: any, ...optionalParams: any[]) {
    if (!environment.production) {
      console.log(msg, optionalParams)
    }
  }
}
