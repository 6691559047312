<h2>Charts</h2>
<button (click)="refresh()" mat-mini-fab color="default">
    <i class="fal fa-sync"></i>
</button>

<div id="charts" *ngIf="data != undefined">
    <div class="row">
        <div class="row-item">
            <app-tocs-count-chart [data]="data.tocs_count_chart"></app-tocs-count-chart>
        </div>
    </div>
    <div class="column">
        <div class="row">
            <div class="row-item">
                <app-doctype-chart [data]="data.doctype_chart"></app-doctype-chart>
            </div>
            <div class="row-item">
                <app-error-chart [data]="data.tocs_error_chart"></app-error-chart>
            </div>
        </div>

        <div class="row">
            <div class="row-item">
                <app-drugs-status-chart [data]="data.drugs_status_chart"></app-drugs-status-chart>
            </div>
            <div class="row-item">
                <app-tocs-explicit-error [data]="data.tocs_explicit_error_chart"></app-tocs-explicit-error>
            </div>
        </div>
    </div>
</div>
