import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class ApiService {

    apiURL: string
    httpOptions = {
        headers: new HttpHeaders({
            'Content-Type': 'application/json',
            'Authorization': 'Token 4SJxKlCP9ucc6pzVXVv9zurURQ96Um7d',
            'X-User-Api-Key': '1ade9001222a075902bc1ea12e0dc643888',
            // 'HM-TOKEN': 'babal'
        })
    };

    constructor(private http: HttpClient) {
        this.apiURL = environment.contentAPIURL
    }

    setApiURL(url: string) {
        this.apiURL = url
        return this
    }

    get(path: string, params?: any): Observable<any> {
        return this.http.get(this.apiURL+path, {headers: this.httpOptions.headers, params: params})
    }

    post(path: string, data: any, params: object = {}): Observable<any> {
        this.httpOptions["params"] = params
        return this.http.post<any>(this.apiURL+path, data, this.httpOptions)
    }

    put(path: string, data: any): Observable<any> {
        return this.http.put<any>(this.apiURL+path, data, this.httpOptions)
    }

    delete(path: string, params: any): Observable<any> {
        return this.http.delete<any>(this.apiURL + path, { headers: this.httpOptions.headers, params: params })
    }
}
