export const environment = {
    contentAPIURL: 'https://beta-api-rd360.360medics.com:442/v1',
    firebaseConfig: {
        apiKey: 'AIzaSyBXYCzQPjNOtLINj4RB4OilYGNGZx9hMJU',
        authDomain: 'beta-medics360.firebaseapp.com',
        databaseURL: 'https://beta-medics360.firebaseio.com',
        projectId: 'beta-medics360',
        storageBucket: 'beta-medics360.appspot.com',
    },
    sfServer: 'https://back.pulselife.beta.360medics.tech',
    production: false
}