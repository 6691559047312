import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { AngularFirestore, AngularFirestoreCollection } from '@angular/fire/firestore';
import { HistoryEntry } from '../model/history-entry.model';


@Injectable({
    providedIn: 'root'
})
export class FirestoreRepo {

    historyID: string

    constructor(private firestore: AngularFirestore) { }

    listenHistory(limit: number = 10, lastDoc: HistoryEntry = null) {
        const path = 'medics_logs'
        const collection: AngularFirestoreCollection<HistoryEntry> = this.firestore.collection<HistoryEntry>(path, ref => {
            let newRef = ref.orderBy("date", "desc")

            if (null !== lastDoc) {
                newRef = newRef.startAfter(lastDoc.date)
            }

            return newRef.limit(limit)
        });

        return this.fetch(collection)
    }

    private fetch(collection: AngularFirestoreCollection<any>, onlyCatchError: boolean = false) {
        return collection.snapshotChanges()
            .pipe(
                map(actions => {
                    const list = {
                        added: [],
                        modified: [],
                        removed: [],
                    }

                    actions.map(a => {
                        const data = a.payload.doc.data()
                        const id = a.payload.doc.id

                        if (!onlyCatchError || (data["error"] != undefined && data["error"] != "")) { // this entry is not an error skip
                            if (onlyCatchError) {
                                console.log(data)
                            }

                            const entry = new HistoryEntry({ id, ...data })

                            switch (a.type) {
                                case "modified":
                                    list.modified.push(entry)
                                    break
                                case "added":
                                    list.added.push(entry)
                                    break;
                                case "removed":
                                    list.removed.push(entry)
                                    break
                            }
                        }
                    })

                    return list
                })
            )
    }
}
