import { Component, OnInit, Input } from '@angular/core';
import {GroupByErrorChart} from '../../../model/model';

@Component({
  selector: 'app-tocs-explicit-error',
  templateUrl: './tocs-explicit-error.component.html',
  styleUrls: ['./tocs-explicit-error.component.scss']
})
export class TocsExplicitErrorComponent implements OnInit {
  @Input() data: GroupByErrorChart[] = []

  constructor() { }

  ngOnInit() {
  }

}
