import { Component, OnInit, Input } from '@angular/core';
import { HistoryEntry } from 'src/app/model/history-entry.model';
import { FirestoreRepo } from 'src/app/services/firestore-repo';
import { HistoryService } from 'src/app/services/history.service';
import { LoggerService } from 'src/app/services/logger.service';
import { LIMIT_VAL_FOR } from 'src/app/services/static/limit';

@Component({
    selector: 'app-history',
    templateUrl: './history.component.html',
    styleUrls: ['./history.component.scss']
})
export class HistoryComponent implements OnInit {
    @Input() history: HistoryEntry[] = []
    limit: number
    offset: number

    firstLoading: boolean

    constructor(private firestoreRepo: FirestoreRepo,
        private historyService: HistoryService,
        private logger: LoggerService) {
        this.limit = LIMIT_VAL_FOR.HISTORY
        this.offset = 0
        this.firstLoading = true
    }

    ngOnInit() {
        this.firestoreRepo
            .listenHistory(this.limit)
            .subscribe((data: any) => {
                this.updateChanges(data)
            })
    }

    updateChanges(documents: any) {
        const modifiedData = documents["modified"]

        if (modifiedData.length > 0) {
            modifiedData.forEach((updatedData: HistoryEntry) => {
                let updated = false

                this.history.forEach((entry: HistoryEntry, index) => {
                    if (updatedData.id === entry.id) {
                        entry.processed_drugs = updatedData.processed_drugs
                        entry.processed_tocs = updatedData.processed_tocs
                        entry.total_drugs = updatedData.total_drugs
                        entry.done = updatedData.done
                        entry.end_date = updatedData.end_date
                        updated = true

                        if (entry.done) {
                            console.log("process done !")
                            // better solution:
                            // fetch new history with uuid = entry.id and replace current entry by new one
                            window.location.reload()
                        }
                    }
                })

                if (!updated) {
                    this.history.unshift(updatedData)
                }
            })
        }
    }

    loadMoreHistory() {
        this.offset = this.offset + this.limit

        this.historyService.getHistory(this.offset, this.limit)
            .subscribe(result => result.map(r => {
                const h = new HistoryEntry(r)
                this.history.push(h)
            }))
    }
}
