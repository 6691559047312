<ul>
    <h2>Databases update history</h2>
    <div class="history">
        <app-history-entry [entry]="entry" *ngFor="let entry of history"></app-history-entry>
    </div>

    <ng-container *ngIf="history.length == 0">
        <mat-card>
            <mat-card-content t-a-c>
                Nothing found
            </mat-card-content>
        </mat-card>
    </ng-container>

    <a mat-stroked-button (click)="loadMoreHistory()"
        *ngIf="history.length >= limit">
        Load more
    </a>
</ul>
