<mat-card class="card" [ngClass]="{'done': entry.processDone(), 'onprogress': !entry.processDone()}">
    <div class="history-entry-card">
        <span class="w-80"><i class="fal fa-database"></i> {{ entry.db_name| uppercase }}</span>
        <img class="flag" src="{{ picture }}" alt="flag">
        <span class="w-80"> {{ entry.lang | uppercase }}</span>
        <span class="subtitle">
            <i class="fal fa-clock"></i> {{ entry.date | date:'yyyy/MM/dd'}} at
            {{ entry.date | date:'HH:mm:ss'}}
        </span>
        <div class="bloc">
            <div class="tocs w-140">
                <strong>Tocs: </strong>
                <span>{{ entry.processed_tocs + '/' + entry.total_drugs }}</span>
            </div>
            <div class="drugs w-140">
                <strong>Drugs: </strong>
                <span>{{ entry.processed_drugs + '/' + entry.total_drugs }}</span>
            </div>
        </div>
        <div class="progress">
            {{ entry.progression().state + '%' }}
        </div>
        <div class="timer">
            {{ duration }}
        </div>
        <div class="actions">
            <button mat-raised-button (click)="showLogs()">Logs</button>
            &nbsp;
            <button mat-flat-button color="warn" (click)="deleteHistory()">Delete <i
                class="far fa-trash-alt"></i></button>
        </div>
    </div>

    <mat-accordion>
        <mat-expansion-panel>
            <mat-expansion-panel-header>
                <mat-panel-title>
                    view analytics
                </mat-panel-title>
            </mat-expansion-panel-header>
            <div class="stats">
                <div class="col">
                    <p><span>Ratio of not viewable files</span> <span class="metric red">{{ ratioOfNotViewableFiles | number:'1.1-1'}}%</span></p>
                    <p><span>Ratio of viewable files</span> <span class="metric green">{{ ratioOfViewableTechnicalFile | number:'1.1-1'}}%</span></p>
                    <p><span>Ratio of broken technical files</span> <span class="metric gray">{{ ratioOfNotViewableFilesWithBrokenSource | number:'1.1-1'}}%</span></p>
                    <p><span>Ratio of drugs with less than twenty sections</span> <span class="metric orange">
                        {{ ratioOfDrugWithLessThanTwentySections | number:'1.1-1'}}%</span></p>
                    <p><span>Ratio of drugs with more than twenty sections</span> <span class="metric green">
                        {{ ratioOfDrugWithMoreThanTwentySections | number:'1.1-1'}}%</span></p>
                </div>
            </div>
        </mat-expansion-panel>

    </mat-accordion>
</mat-card>
