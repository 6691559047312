import { Component, OnInit, Input } from '@angular/core';
import { TocLog } from 'src/app/model/toc-log.model';
import { MatDialog } from '@angular/material/dialog';
import { TocExceptionComponent } from 'src/app/modal/toc-exception/toc-exception.component';
import { HistoryService } from 'src/app/services/history.service';
import { LoggerService } from 'src/app/services/logger.service';

@Component({
    selector: 'app-toc-monitoring-entry',
    templateUrl: './toc-monitoring-entry.component.html',
    styleUrls: ['./toc-monitoring-entry.component.scss']
})
export class TocMonitoringEntryComponent implements OnInit {

    @Input() index: number
    @Input() toc: TocLog
    @Input() markExceptionAsTreated: boolean

    source: string
    value: string
    expected: string

    constructor(public dialog: MatDialog, private historyService: HistoryService, private logger: LoggerService) {}

    ngOnInit() {
        this.value = this.toc.name
        this.source = this.toc.source_url
    }

    openDialog() {
        if (this.toc.fireError()) {
            const dialogRef = this.dialog.open(TocExceptionComponent, {
                width: '400px',
                data: { value: this.value, expected: this.expected, source: this.source }
            });

            dialogRef.afterClosed().subscribe(result => {
                this.value = result.value;
                this.expected = result.expected;
                this.historyService.notifyException(this.value, this.expected)
                    .subscribe(() => {
                        this.historyService.markExeptionAsTreated(this.toc.id)
                    }, (err) => { this.logger.error(err)})
            });
        }
    }

}
