<h2>Drugs Logs </h2>
<button (click)="refresh()" mat-mini-fab color="default">
    <i class="fal fa-sync"></i>
</button>
<mat-tab-group animationDuration="500ms">
    <mat-tab label="List">
        <ul>
            <app-drug-monitoring-entry [drug]="drug" [index]="drugs.length - i" *ngFor="let drug of drugs ; index as i">
            </app-drug-monitoring-entry>

            <a mat-stroked-button (click)="loadMoreDrugs()" *ngIf="drugs.length >= limit">
                Load more drugs
            </a>

            <div t-a-c w-100>
                <span *ngIf="drugsLoading">
                    <i class="fal fa-spinner fa-spin"></i>
                </span>
                <p *ngIf="drugs.length === 0 && !drugsLoading">
                    No drugs found
                </p>
            </div>
        </ul>
    </mat-tab>
    <mat-tab label='{{ "Errors [" + drugsErrors.length + "]" }}'>
        <ul>
            <app-drug-monitoring-entry [drug]="drug" [index]="drugsErrors.length - i"
                *ngFor="let drug of drugsErrors ; index as i">
            </app-drug-monitoring-entry>

            <a mat-stroked-button (click)="loadMoreDrugsErrors()"
                *ngIf="drugsErrors.length >= limit">
                Load more errors
            </a>

            <div t-a-c w-100>
                <span *ngIf="drugsErrorsLoading">
                    <i class="fal fa-spinner fa-spin"></i>
                </span>
                <p *ngIf="drugsErrors.length === 0 && !drugsErrorsLoading">
                    No error found
                </p>
            </div>
        </ul>
    </mat-tab>
</mat-tab-group>
