import {Component, OnInit} from '@angular/core';
import {Tool} from 'src/app/model/tool.model';
import {FormArray, FormControl, FormGroup, Form, Validators} from '@angular/forms';
import {ToolService} from 'src/app/services/tool.service';
import {LoggerService} from 'src/app/services/logger.service';
import {MatSnackBar} from '@angular/material/snack-bar';

const UR_VALIDATION_REGEX = `(https?:\\/\\/(?:www\\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+
            [a-zA-Z0-9]\\.[^\\s]{2,}|www\\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\\.[^\\s]{2,}|
            https?:\\/\\/(?:www\\.|(?!www))[a-zA-Z0-9]+\\.[^\\s]{2,}|www\\.[a-zA-Z0-9]+\\.[^\\s]{2,})`;

@Component({
    selector: 'app-tool-edit',
    templateUrl: './tool-edit.component.html',
    styleUrls: ['./tool-edit.component.scss']
})
export class ToolEditComponent implements OnInit {

    tool: Tool;
    currentKeywords = [];
    form: FormGroup;
    newIndexCount: number;

    constructor(private toolService: ToolService, private logger: LoggerService, private snackBar: MatSnackBar) {
        this.newIndexCount = 0;
    }

    ngOnInit() {
        const defaultKeywordsFormGroups = this.currentKeywords.length > 0
            ? this.createItemsFrom(this.currentKeywords)
            : [this.createItem()];

        this.form = new FormGroup({
            keywords: new FormArray(defaultKeywordsFormGroups)
        });
    }

    createItemsFrom(list: Array<{ route: string, keywords: string, title: string, description: string }>): FormGroup[] {
        const groups: FormGroup[] = [];
        list.forEach(item => {
            groups.push(new FormGroup({
                title: new FormControl(item.title),
                route: new FormControl(item.route),
                keywords: new FormControl(item.keywords),
                description: new FormControl(item.description)
            }));
        });

        return groups;
    }

    createItem(): FormGroup {
        return new FormGroup({
            title: new FormControl(),
            route: new FormControl(),
            keywords: new FormControl(),
            description: new FormControl()
        });
    }

    get keywords(): FormArray {
        return this.form.get('keywords') as FormArray;
    }

    addItem() {
        this.keywords.push(this.createItem());
        this.autoSave();
    }

    autoSave() {
        if (!this.form.valid) {
            return;
        }

        this.newIndexCount++;

        if (this.newIndexCount === 2) {
            this.newIndexCount = 0;
            this.save();
        }
    }

    removeItem(index: number) {
        this.keywords.removeAt(index);
    }

    save() {
        this.toolService.updateToolKeywords(this.tool.id, this.keywords.value)
            .subscribe(
                res => this.logger.log(res),
                (err) => this.logger.error(err),
                () => this.snackBar.open('Saved with success !', '', {duration: 4000})
            );
    }
}
