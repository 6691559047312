import { ModelParent } from "./model-parent"
import {Stats} from './model';

export class HistoryEntry extends ModelParent {
    id: string
    uuid?: string
    date: number
    db_name: string
    lang: string
    done: boolean
    end_date: number
    processed_tocs: number
    processed_drugs: number
    total_drugs: number
    file_stats: Stats

    public constructor(data: any) {
        super()
        super.hydrate(this, data)
        this.setDefaultValues(data)
    }

    setDefaultValues(data: any) {
        if (!data.hasOwnProperty("processed_tocs")) {
            this.processed_tocs = 0
        }

        if (!data.hasOwnProperty("file_stats")) {
            this.file_stats = {
                workingFiles: 0,
                brokenFiles: 0,
                missingFiles: 0,
            }
        }

        if (!data.hasOwnProperty("processed_drugs")) {
            this.processed_drugs = 0
        }

        if (!data.hasOwnProperty("total_drugs")) {
            this.total_drugs = 1
        }

        if (!data.hasOwnProperty("done")) {
            this.done = false
        }

        if (!data.hasOwnProperty("end_date")) {
            this.end_date = 0
        }

        if (!data.hasOwnProperty("uuid")) {
            this.uuid = this.id
        }
    }

    progression() {
        const ttDrugs = this.total_drugs > 0 ? this.total_drugs : 1
        const drugsP = (this.processed_drugs / ttDrugs) * 100
        const tocsP = (this.processed_tocs / ttDrugs) * 100
        const state = (drugsP + tocsP)/2

        return {
            tocs: tocsP.toFixed(0),
            drugs: drugsP.toFixed(0),
            state: state.toFixed(0)
        }
    }

    processDone() {
        return this.done == true
    }

    processDurationLowerThanHour(hours: number) {
        const timeout = hours * 3600000 + +this.date
        return timeout > Date.now()
    }
}
