import {Injectable} from '@angular/core';
import {ApiService} from './http/api.service';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {Tool} from '../model/tool.model';

@Injectable({
    providedIn: 'root'
})
export class ToolService {

    constructor(private apiService: ApiService) {
    }

    getTools(q: string, lang: string, letter: string): Observable<Tool[]> {
        return this.apiService.get('/tools', {q, lang, letter})
            .pipe(
                map((tools) => tools.map((tool: any) => new Tool(tool)))
            );
    }

    getToolKeywords(id: number) {
        return this.apiService.get('/tool/keywords', {id});
    }

    updateToolKeywords(id: number, keywords: Array<{ title: string, description: string, route: string, keywords: string }>) {
        return this.apiService.put(`/tool/keywords?id=${id}`, keywords);
    }
}
