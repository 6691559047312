import { ModelParent } from "./model-parent"

export class DrugLog extends ModelParent {
    id: string
    cis: string
    date: number
    name: string
    step: string
    success: boolean
    error: string

    constructor(data: any) {
        super()
        super.hydrate(this, data)
        this.setDefaultValues(data)
    }

    setDefaultValues(data: any) {
        if (!data.hasOwnProperty("success")) {
            this.success = false
        }

        if (!data.hasOwnProperty("error")) {
            this.error = ""
        }
    }

    fireError(): boolean {
        return !this.success && this.error != ""
    }

    processDone(): boolean {
        return this.fireError() || this.success
    }
}
