import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { AdminService } from 'src/app/services/admin.service';
import { LoggerService } from 'src/app/services/logger.service';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmationDialogComponent } from '../confirmation-dialog/confirmation-dialog.component';

@Component({
    selector: 'app-admin-panel',
    templateUrl: './admin-panel.component.html',
    styleUrls: ['./admin-panel.component.scss']
})
export class AdminPanelComponent implements OnInit {

    @Output() closePanel = new EventEmitter<boolean>()

    databases: Array<string> = ["cima", "ansm", "mhra", "aifa"]
    selectedDatabase: string
    showDrugsInput: boolean

    constructor(private adminService: AdminService, private logger: LoggerService, public dialog: MatDialog) {
        this.selectedDatabase = null
        this.showDrugsInput = false
    }

    ngOnInit() {
    }

    updateDb(e: any) {
        this.selectedDatabase = e.value;
    }

    updateAllDrugs() {
        // e.preventDefault()

        this.adminService.update(this.selectedDatabase, [], true).subscribe(
            (resp) => { this.logger.log(resp) },
            (error) => { this.logger.error(error) },
            () => { this.logger.log("completed") },
        )
        this.closePanel.emit(true)
    }

    updateDrugs(drugs: string) {
        this.adminService.update(this.selectedDatabase, drugs.split(","))
            .subscribe(
                (resp) => { this.logger.log(resp) },
                (error) => { this.logger.error(error) },
                () => { this.logger.log("completed") },
            )
        this.closePanel.emit(true)
    }

    openDialog(): void {
        const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
          width: '400px',
          data: "Etes vous sûr de vouloir mettre à jour tout les médicaments ? Cette étape peut prendre plusieurs heures.",
        });

        dialogRef.afterClosed().subscribe(result => {
            if(result) {
                this.updateAllDrugs()
            }
        });
      }
}

