<div class="modal">
    <h1 mat-dialog-title>Exception Form</h1>
    <div mat-dialog-content>
        <p>Visit this <a href="{{ data.source }}" target="_blank"><em>link</em></a> and fill the expected name</p>
        <mat-form-field style="width:100%">
            <mat-label>Drug name</mat-label>
            <input matInput [(ngModel)]="data.value">
        </mat-form-field>

        <mat-form-field style="width:100%">
            <mat-label>Drug expected name</mat-label>
            <input matInput [(ngModel)]="data.expected">
        </mat-form-field>
    </div>
    <div mat-dialog-actions>
        <button mat-button (click)="onNoClick()">Cancel</button>
        <button mat-button [mat-dialog-close]="{ value: data.value, expected: data.expected }" cdkFocusInitial>Ok</button>
    </div>
</div>
