<ng-container *ngIf="load">
    <div id="navigation">
        <button mat-stroked-button (click)="scrollToElement(drugs)"> Drugs</button>
        <button mat-stroked-button (click)="scrollToElement(tocs)">Tocs</button>
        <button mat-stroked-button (click)="scrollToElement(charts)">Charts</button>
    </div>
    <div id="history">
        <div class="round">
            {{ historyEntry.processed_tocs }}<br> tocs
        </div>
        <div class="round">
            {{ historyEntry.processed_drugs }}<br> drugs
        </div>
        <div class="round">
            Total<br> {{ historyEntry.total_drugs }}
        </div>
    </div>

    <button mat-raised-button color="default" *ngIf="false" (click)="reProcessedErroredDrugs()" class="retryButton">
        <i class="far fa-play"></i> re-processed drugs in error
    </button>

    <div class="monitoring">
        <div #drugs>
            <mat-card class="section">
                <mat-card-content>
                    <app-drugs-monitoring></app-drugs-monitoring>
                </mat-card-content>
            </mat-card>
        </div>

        <div #tocs>
            <mat-card class="section">
                <mat-card-content>
                    <app-tocs-monitoring></app-tocs-monitoring>
                </mat-card-content>
            </mat-card>
        </div>

        <div #charts>
            <mat-card class="section">
                <mat-card-content>
                    <app-monitoring-chart></app-monitoring-chart>
                </mat-card-content>
            </mat-card>
        </div>

    </div>
</ng-container>
