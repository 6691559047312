import {Component, Input, Output, EventEmitter, OnInit, OnChanges, SimpleChanges} from '@angular/core';
import {ToolService} from '../../services/tool.service';
import {Tool} from '../../model/tool.model';

@Component({
    selector: 'app-alphabetical',
    templateUrl: './alphabetical.component.html',
    styleUrls: ['./alphabetical.component.scss']
})
export class AlphabeticalComponent implements OnChanges {
    @Output() notify = new EventEmitter();
    @Input() defaultLetter: string;

    selectedLetter: string;
    letters: Array<string> = ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N',
        'O', 'P', 'Q', 'R', 'S', 'T', 'U', 'V', 'W', 'X', 'Y', 'Z'];

    constructor() {
    }

    ngOnChanges(changes: SimpleChanges) {
        this.selectedLetter = changes.defaultLetter.currentValue.toLocaleUpperCase();
        console.log(this.selectedLetter);
    }

    sendEvent(letter: string) {
        this.selectedLetter = letter;
        this.notify.emit({letter});
    }
}
