import { Component, OnInit } from '@angular/core';
import { DrugLog } from 'src/app/model/drug-log.model';
import { HistoryService } from 'src/app/services/history.service';
import { LoggerService } from 'src/app/services/logger.service';
import { LIMIT_VAL_FOR } from 'src/app/services/static/limit';

@Component({
    selector: 'app-drugs-monitoring',
    templateUrl: './drugs-monitoring.component.html',
    styleUrls: ['./drugs-monitoring.component.scss']
})
export class DrugsMonitoringComponent implements OnInit {
    drugs: DrugLog[] = []
    drugsErrors: DrugLog[] = []

    drugsLoading: boolean
    drugsErrorsLoading: boolean
    limit: number

    constructor(private historyService: HistoryService, private logger: LoggerService) {
        this.drugsLoading = true
        this.drugsErrorsLoading = true
        this.limit = LIMIT_VAL_FOR.DRUGS
    }

    ngOnInit() {
        this.refresh()
    }

    private loadDrugsFromCloudSQL(offset: number, limit: number) {
        this.historyService.getDrugs(offset, limit)
            .subscribe(results => {
                if (null != results) {
                    results.map((result: any) => this.drugs.push(new DrugLog(result)))
                }
            }, (err) => { this.logger.error(err) }, () => { this.drugsLoading = false })
    }

    private loadDrugsErrorsFromCloudSQL(offset: number, limit: number) {
        this.historyService.getDrugs(offset, limit, false)
            .subscribe(results => {
                if (null != results) {
                    results.map((result: any) => this.drugsErrors.push(new DrugLog(result)))
                }
            }, (err) => { this.logger.error(err) }, () => { this.drugsErrorsLoading = false })
    }

    refresh() {
        this.drugs = []
        this.drugsErrors = []
        this.loadDrugsFromCloudSQL(0, LIMIT_VAL_FOR.DRUGS)
        this.loadDrugsErrorsFromCloudSQL(0, LIMIT_VAL_FOR.DRUGS)
    }

    loadMoreDrugs() {
        const offset = this.drugs.length
        this.loadDrugsFromCloudSQL(offset, LIMIT_VAL_FOR.TOCS)
    }

    loadMoreDrugsErrors() {
        const offset = this.drugsErrors.length
        this.loadDrugsErrorsFromCloudSQL(offset, LIMIT_VAL_FOR.TOCS)
    }
}
