import { Component, OnInit, AfterViewInit } from '@angular/core';
import { HistoryService } from 'src/app/services/history.service';
import { ChartData } from 'src/app/model/chart-data.model';

@Component({
    selector: 'app-monitoring-chart',
    templateUrl: './monitoring-chart.component.html',
    styleUrls: ['./monitoring-chart.component.scss']
})
export class MonitoringChartComponent implements OnInit, AfterViewInit {
    data: ChartData

    constructor(private historyService: HistoryService) {

    }

    ngOnInit() {
        this.refresh()
    }

    ngAfterViewInit() {

    }

    refresh() {
        this.historyService.getChartsData()
            .subscribe((data: any) => this.data = new ChartData(data))
    }
}
