<header>
    <nav>
        <div id="logo">
            <img src="assets/images/logo.png" alt="360 medics">
        </div>
        <ul class="menu">
            <li [routerLink]="['/db-logs']" routerLinkActive="active">Monitoring</li>
            <li [routerLink]="['/tools']" routerLinkActive="active">Tools</li>
        </ul>
    </nav>
</header>
