import { Component, OnInit, Input } from '@angular/core';
import { ChartType, ChartOptions } from 'chart.js';
import { Label } from 'ng2-charts';
import { COLOR } from 'src/app/services/static/color';
import { HistoryService } from 'src/app/services/history.service';
import {DoctypeChart} from '../../../model/model';

@Component({
    selector: 'app-doctype-chart',
    templateUrl: './doctype-chart.component.html',
    styleUrls: ['./doctype-chart.component.scss']
})
export class DoctypeChartComponent implements OnInit {

    @Input() data: DoctypeChart[] = []

    pieChartLabels: Label[] = [];
    pieChartData: number[] = [];

    pieChartType: ChartType = 'doughnut';
    pieChartLegend = true;

    pieChartOptions: ChartOptions = {
        responsive: true,
        legend: {
            position: 'right',
        },
        plugins: {
            datalabels: {
                formatter: (value, ctx) => {
                    const label = ctx.chart.data.labels[ctx.dataIndex];
                    return label;
                },
            },
        },
        title: {
            display: true,
            position: 'top',
            text: 'Tocs count by doctype'
        }
    };

    // pieChartPlugins = [pluginDataLabels];
    pieChartColors = [
        {
            backgroundColor: [
                COLOR.GREEN,
                COLOR.GRAY,
                COLOR.RED
            ],
        },
    ];


    constructor(private historyService: HistoryService) { }

    ngOnInit() {
        this.data.forEach((entry: DoctypeChart) => {
            this.pieChartData.push(+entry.count)
            this.pieChartLabels.push(entry.source_doctype)
        })
    }

}
