import { Component, OnInit, Input, ViewEncapsulation, ElementRef } from '@angular/core';
import { HistoryEntry } from 'src/app/model/history-entry.model';
import { FirestoreRepo } from 'src/app/services/firestore-repo';
import { HistoryService } from 'src/app/services/history.service';
import { LoggerService } from 'src/app/services/logger.service';
import { Router } from '@angular/router';
import { bindCallback } from 'rxjs';

@Component({
    selector: 'app-history-entry',
    templateUrl: './history-entry.component.html',
    styleUrls: ['./history-entry.component.scss']
})
export class HistoryEntryComponent implements OnInit {
    @Input() entry: HistoryEntry;

    loadMonitoring: boolean
    showDeleteButton: boolean

    duration: string
    timer: any
    picture: string
    uuid: string

    numberOfViewableTechnicalFile: number
    ratioOfViewableTechnicalFile: number
    ratioOfNotViewableFilesWithBrokenSource: number = 0
    ratioOfNotViewableFiles: number = 0
    numberOfProcessedFiles: number = 0
    ratioOfDrugWithMoreThanTwentySections: number
    ratioOfDrugWithLessThanTwentySections: number

    constructor(
        private firestoreRepo: FirestoreRepo,
        private historyService: HistoryService,
        private logger: LoggerService,
        private elem: ElementRef,
        private router: Router
    ) {
        this.duration = "00:00:00"
    }

    ngOnInit() {
        this.firestoreRepo.historyID = this.entry.id
        this.uuid = this.entry.uuid
        this.loadMonitoring = false
        this.showDeleteButton = false;
        this.picture = this.getPicture(this.entry.lang)
        this.handleDuration()

        this.historyService.getNumberOfTechnicalFileViewable(this.uuid)
            .subscribe(({ number_of_files_viewable, total_files_processed }) => {
                this.numberOfViewableTechnicalFile = number_of_files_viewable
                this.ratioOfViewableTechnicalFile = this.computeRatio(number_of_files_viewable, total_files_processed)
                this.numberOfProcessedFiles = total_files_processed

                this.computeNotViewableFilesRatio()
                this.computeRatioOfFileSections()
            })
    }

    private computeNotViewableFilesRatio() {
        this.historyService.getNumberOfFileNotViewableGroupByDoctype(this.uuid)
            .subscribe((list) => {
                let notViewableFilesButNormal: number = 0
                let notViewableFiles: number = 0

                const listOfValidLink = list.filter(({ doctype }) => ["brokenLink", "unknown"].indexOf(doctype) == -1)
                listOfValidLink.forEach(({ count }) => notViewableFiles += count)

                const listOfBrokenLink = list.filter(({ doctype }) => ["brokenLink", "unknown"].indexOf(doctype) > -1)
                listOfBrokenLink.forEach(({ count }) => notViewableFilesButNormal += count)

                this.ratioOfNotViewableFiles = this.computeRatio(notViewableFiles, this.numberOfProcessedFiles)
                this.ratioOfNotViewableFilesWithBrokenSource = this.computeRatio(notViewableFilesButNormal, this.numberOfProcessedFiles)
            })
    }

    private computeRatioOfFileSections() {
        this.historyService.getListOfFileGroupByNbTocsAndDoctype(this.uuid)
            .subscribe((list) => {
                let a: number = 0
                let b: number = 0

                const y = list.filter(({ number_of_sections }) => number_of_sections >= 20)
                y.forEach(({ count }) => a += count)

                const x = list.filter(({ number_of_sections }) => number_of_sections < 20)
                x.forEach(({ count }) => b += count)
                console.log(x, b)

                console.log(a, b)

                this.ratioOfDrugWithLessThanTwentySections = this.computeRatio(b, this.numberOfViewableTechnicalFile)
                this.ratioOfDrugWithMoreThanTwentySections = this.computeRatio(a, this.numberOfViewableTechnicalFile)
            })
    }

    private computeRatio(input: number, total: number): number {
        return (input * 100) / total
    }

    private getPicture(lang: string): string {
        return "../../../assets/images/flags/" + lang + ".png"
    }

    deleteHistory() {
        this.historyService.removeHistory(this.entry.uuid)
            .subscribe(
                () => { },
                (err) => this.logger.error(err)
            )
        this.elem.nativeElement.remove()
    }

    formatDuration(seconds: number) {
        let hours = 0
        let minutes = 0

        if (seconds > 60) {
            minutes = Math.trunc(seconds / 60)
            seconds = Math.floor(seconds % 60)
        }

        if (minutes > 60) {
            hours = Math.trunc(minutes / 60)
            minutes = Math.floor(minutes % 60)
        }

        this.duration = `${hours < 10 ? '0' + hours : hours}:${minutes < 10 ? '0' + minutes : minutes}:${seconds < 10 ? '0' + seconds : seconds}`
    }

    handleDuration() {
        if (this.entry.done) {
            this.formatDuration(Math.floor((this.entry.end_date - this.entry.date) / 1000))
        } else {
            this.timer = setInterval(() => {
                this.formatDuration(Math.floor((Date.now() - this.entry.date) / 1000))
            }, 1000)
        }
    }

    showLogs() {
        this.historyService.history = this.entry
        this.router.navigate(['/db-logs', this.entry.uuid, 'detail'])
    }
}
