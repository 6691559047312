<mat-drawer-container class="drawer-content" autosize>
    <mat-drawer #drawer class="sidenav" mode="over" [position]="'end'">
        <app-admin-panel (closePanel)="drawer.opened = false">
        </app-admin-panel>
    </mat-drawer>
    <app-menu></app-menu>
    <div class="container-fluid">
        <button class="adminPanel" (click)="drawer.toggle()"><i class="fal fa-cogs"></i> <br> Console</button>
        <app-history [history]="history"></app-history>
    </div>

</mat-drawer-container>
