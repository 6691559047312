<!-- <div class="bg-img"></div>
<div class="bg-dark"></div> -->
<div id="login">
    <div></div>
    <mat-card class="login-form">
        <header>
            <img id="logo" src="../../../assets/images/logo.png" alt=""> <span>Connexion</span>
        </header>
        <mat-card-content>
            <form [formGroup]="loginForm" (submit)="login()">
                <div class="error" *ngIf="displayLoginError">
                    <p>
                        Email ou mot de passe incorrecte
                    </p>
                </div>
                <mat-form-field appearance="fill">
                    <mat-label>Email</mat-label>
                    <input matInput formControlName="email" placeholder="aaz@medics.com" autocomplete="off">
                    <mat-error *ngIf="email.hasError('email') && !email.hasError('required')">
                        Entrez un email valide
                    </mat-error>
                    <mat-error *ngIf="email.hasError('required')">
                        l'email est <strong>obligatoire</strong>
                    </mat-error>
                </mat-form-field>
                <br>
                <mat-form-field appearance="fill">
                    <mat-label>Mot de passe</mat-label>
                    <input type="password" matInput formControlName="password" autocomplete="off">
                    <mat-error *ngIf="password.hasError('required')">
                        le mot de passe est <strong>obligatoire</strong>
                    </mat-error>
                </mat-form-field>
                <br>
                <button type="submit" mat-raised-button color="primary">
                    <span *ngIf="!loading">Se connecter</span>
                    <span *ngIf="loading">chargement...</span>
                </button>
            </form>
        </mat-card-content>
    </mat-card>

    <div class="pills">
        <i class="fal fa-tablets"></i>
        <i class="fal fa-syringe"></i>
    </div>
    <div class="bottom">
        <div class="bottom-card"></div>
    </div>
</div>
