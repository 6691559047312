import { Injectable } from '@angular/core';

import { auth } from 'firebase/app';
import { AngularFireAuth } from "@angular/fire/auth";
import { User } from 'firebase';
import { Router } from '@angular/router';


@Injectable({
  providedIn: 'root'
})
export class AuthService {

  user: User;

  constructor(public afAuth: AngularFireAuth, private router: Router) { 
    // if user is authenticate we save him in the local storage
    this.afAuth.authState.subscribe(user => {
      if (user) {
        this.user = user;
        localStorage.setItem('monitConsoleUser', JSON.stringify(this.user));
      } else {
        localStorage.setItem('monitConsoleUser', null);
      }
    })
  }

  async login(email: string, password: string) {
    const result = await this.afAuth.auth.signInWithEmailAndPassword(email, password)
    this.router.navigate(['/'])
  }

  async logout() {
    await this.afAuth.auth.signOut();
    localStorage.removeItem('monitConsoleUser');

    this.router.navigate(['/login'])
  }

  get isLoggedIn(): boolean {
    const user = JSON.parse(localStorage.getItem('monitConsoleUser')) as User;
    if (null == user) {
      return false
    }

    return true;
  }
}
