import { Component, OnInit, Input } from '@angular/core';
import { DrugLog } from 'src/app/model/drug-log.model';

@Component({
  selector: 'app-drug-monitoring-entry',
  templateUrl: './drug-monitoring-entry.component.html',
  styleUrls: ['./drug-monitoring-entry.component.scss']
})
export class DrugMonitoringEntryComponent implements OnInit {

  @Input() index: number
  @Input() drug: DrugLog

  constructor() {  }

  ngOnInit() {
   
  }

}
