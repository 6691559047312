import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import {TocException} from '../../model/model';

@Component({
  selector: 'app-toc-exception',
  templateUrl: './toc-exception.component.html',
  styleUrls: ['./toc-exception.component.scss']
})
export class TocExceptionComponent {

  constructor(
    public dialogRef: MatDialogRef<TocExceptionComponent>,
    @Inject(MAT_DIALOG_DATA) public data: TocException) { }

  onNoClick(): void {
    this.dialogRef.close();
  }
}
