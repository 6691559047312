import { Component, OnInit, Input, ViewChild, ElementRef, Output, EventEmitter, AfterViewInit, OnChanges, SimpleChanges } from '@angular/core';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { MatAutocomplete, MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { FormControl } from '@angular/forms';
import { startWith, map } from 'rxjs/operators';
import { Observable } from 'rxjs';

export interface ListItem {
    name: string
    value: string | number
}

export type ChipSelection = ListItem[]
export type ChipValue = string | number

@Component({
    selector: 'app-chips',
    templateUrl: './chips.component.html',
    styleUrls: ['./chips.component.scss']
})
export class ChipsComponent implements OnInit, OnChanges {
    selectedItemsList: ListItem[] = []
    separatorKeysCodes: number[] = [ENTER, COMMA];
    filteredList: Observable<ListItem[]>
    listCtrl = new FormControl();

    list: ListItem[] = []
    values: ChipValue[] = []

    @Input() placeholder: string
    @Input() initialList: string[] | Object[] = []
    @Input() removable: boolean

    @Input() nameKey: string
    @Input() valueKey: string

    @Input()
    get selection(): ChipValue[] {
        return this.values
    }

    @Output() selectionChange = new EventEmitter()
    set selection(val: ChipValue[]) {
        this.values = val;
        this.selectionChange.emit(this.values);
    }

    @ViewChild('listInput') listInput: ElementRef<HTMLInputElement>
    @ViewChild('auto') matAutocomplete: MatAutocomplete

    constructor() {
        this.placeholder = 'New item'
        this.removable = true

        this.filteredList = this.listCtrl.valueChanges.pipe(
            startWith(null),
            map((selection: string | null) => selection ? this._filter(selection) : this.list.slice())
        )
    }

    ngOnInit() {

    }

    ngOnChanges(changes: SimpleChanges) {
        console.log(changes)
        if (changes["initialList"] != undefined && changes["initialList"].firstChange === false) {
            this.initialList.forEach((item) => {
                if (typeof (item) === "string") {
                    this.list.push({ name: item, value: item })
                } else {
                    const elem = item as Object
                    if (elem.hasOwnProperty(this.nameKey) && elem.hasOwnProperty(this.valueKey)) {
                        this.list.push({ name: elem[this.nameKey], value: elem[this.valueKey] })
                    }
                }
            })
        }
        if (changes["selection"] != undefined && changes["selection"].firstChange === false) {
            this.selectedItemsList = this.list.filter((elem) => {
                return this.selection !== null && this.selection.indexOf(elem.value) > -1
            })
        }
    }

    remove(selectionRemoved: string | number) {
        this.values = this.values.filter(elem => elem !== selectionRemoved)
        this.selectedItemsList = this.selectedItemsList.filter((elem) => elem.value !== selectionRemoved)
    }

    selected(event: MatAutocompleteSelectedEvent): void {
        const selection = {
            name: event.option.viewValue,
            value: event.option.value
        }

        this.selectedItemsList.push(selection);
        this.values.push(event.option.value)

        this.listInput.nativeElement.value = '';
        this.listCtrl.setValue(null);
    }

    isSelected(q: string) {
        return undefined != this.selectedItemsList.find((elem) => elem.name === q)
    }

    private _filter(selection: string) {
        if (typeof (selection) != "string") {
            return this.list
        }
        const filterValue = selection.toLowerCase()
        return this.list.filter((elem) => elem.name.toLowerCase().includes(filterValue))
    }
}
