<mat-form-field class="chips-selection">
    <mat-chip-list #chipList aria-label="Multi selection">
        <mat-chip *ngFor="let item of selectedItemsList" [removable]="removable"
            (removed)="remove(item.value)" color="primary">
            {{item.name}}
            <mat-icon matChipRemove *ngIf="removable">cancel</mat-icon>
        </mat-chip>

        <input placeholder="{{ placeholder }}" #listInput [formControl]="listCtrl" [matAutocomplete]="auto"
            [matChipInputFor]="chipList" [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
        >
    </mat-chip-list>
    <mat-autocomplete #auto="matAutocomplete" (optionSelected)="selected($event)">
        <mat-option *ngFor="let item of filteredList | async" [value]="item.value" [disabled]="isSelected(item.name)">
            {{item.name}}
        </mat-option>
    </mat-autocomplete>
</mat-form-field>