<div class="container">
    <table>
        <thead>
            <tr>
                <th>count</th>
                <th>Error</th>
            </tr>
        </thead>
        <tbody>
            <tr *ngFor="let item of data">
                <td>{{ item.count }}</td>
                <td title="{{ item.error }}">{{ item.error }}</td>
            </tr>
        </tbody>
    </table>
</div>
