import { Component, OnInit } from "@angular/core";
import { ToolService } from "src/app/services/tool.service";
import { Tool } from "src/app/model/tool.model";
import { MatDialog } from "@angular/material/dialog";
import { ToolEditComponent } from "../tool-edit/tool-edit.component";
import { LoggerService } from "src/app/services/logger.service";

@Component({
  selector: "app-tools",
  templateUrl: "./tools.component.html",
  styleUrls: ["./tools.component.scss"],
})
export class ToolsComponent implements OnInit {
  languages: Array<string> = ["fr", "es", "uk"];
  selectedLang: string;
  selectedLetter: string;
  searchTerm: string;
  tools: Tool[] = [];
  allTools: Tool[] = [];
  loading: boolean;

  constructor(
    private toolService: ToolService,
    public dialog: MatDialog,
    private logger: LoggerService
  ) {
    this.selectedLetter = "a";
    this.selectedLang = "fr";
    this.searchTerm = "";
  }

  ngOnInit() {
    this.getTools();
  }

  editKeywords(tool: Tool) {
    let currentKeywords = [];
    this.toolService.getToolKeywords(tool.id).subscribe(
      (keywords) => (currentKeywords = keywords),
      (err) => this.logger.error(err),
      () => {
        const dialogRef = this.dialog.open(ToolEditComponent, {
          maxWidth: "100vw",
          maxHeight: "100vh",
          height: "100%",
          width: "100%",
          panelClass: "full-screen-modal",
        });
        dialogRef.componentInstance.tool = tool;
        dialogRef.componentInstance.currentKeywords = currentKeywords;

        dialogRef.afterClosed().subscribe((result) => {
          console.log(`Dialog result: ${result}`);
        });
      }
    );
  }

  filter(q: string) {
    try {
      this.tools = this.allTools.filter((word) =>
        word.name.toLowerCase().includes(q)
      );
    } catch (error) {
      console.error("allTools is null");
    }
  }

  search(q: string) {
    this.searchTerm = q;
    this.selectedLetter = "";
    this.selectedLang = "";
    this.getTools();
  }

  onLangUpdate(lang: string) {
    this.selectedLang = lang;
    this.searchTerm = "";

    if (lang === "uk") {
      this.selectedLang = "gb";
    }

    if (this.selectedLetter === "") {
      this.selectedLetter = "a";
    }
    this.getTools();
  }

  onLetterFilterUpdate(e) {
    this.selectedLetter = e.letter.toLowerCase();
    this.searchTerm = "";
    if (this.selectedLang === "") {
      this.selectedLang = "fr";
    }
    this.getTools();
  }

  getTools() {
    this.tools = [];
    this.loading = true;
    this.toolService
      .getTools(this.searchTerm, this.selectedLang, this.selectedLetter)
      .subscribe((tools: Tool[]) => {
        this.allTools = tools;
        this.tools = tools;
        this.loading = false;
      });
  }
}
