import { Component, OnInit } from '@angular/core';
import { TocLog } from 'src/app/model/toc-log.model';
import { HistoryService } from 'src/app/services/history.service';
import { LoggerService } from 'src/app/services/logger.service';
import { LIMIT_VAL_FOR } from 'src/app/services/static/limit';

export interface Toc {
	index: string
	cis: string
	date: string
	time: string
	name: string
	step: string
	nb_tocs: number
	source: string
	success: boolean
	error: string
	source_url: string
	source_doctype: string
	source_page_exist: string
}

@Component({
	selector: 'app-tocs-monitoring',
	templateUrl: './tocs-monitoring.component.html',
	styleUrls: ['./tocs-monitoring.component.scss']
})
export class TocsMonitoringComponent implements OnInit {
	tocs: TocLog[] = []
	tocsErrors: TocLog[] = []

	tocsErrorLoading: boolean
	tocsLoading: boolean

	dataSource: Toc[];
	limit: number;

	nbExceptionsTreatedByThisSession: number

	constructor(private historyService: HistoryService, private logger: LoggerService) {
		this.tocsErrorLoading = true
		this.tocsLoading = true
		this.limit = LIMIT_VAL_FOR.TOCS
	}

	ngOnInit() {
		this.refresh()
		this.nbExceptionsTreatedByThisSession = this.historyService.listExceptionsMarkAsTreated().length
	}

	private loadTocsFromCloudSQL(offset: number, limit: number) {
		this.historyService.getTocs(offset, limit)
			.subscribe(results => {
				if (null != results) {
					results.map((result: any) => this.tocs.push(new TocLog(result)))
				}
			}, (err) => { this.logger.error(err) }, () => { this.tocsLoading = false })
	}

	private loadTocsErrorsFromCloudSQL(offset: number, limit: number) {
		this.historyService.getTocs(offset, limit, false)
			.subscribe(results => {
				if (null != results) {
					results.map((result: any) => this.tocsErrors.push(new TocLog(result)))
				}
			}, (err) => { this.logger.error(err) }, () => { this.tocsErrorLoading = false })
	}

	refresh() {
		this.tocs = []
		this.tocsErrors = []
		this.loadTocsFromCloudSQL(0, LIMIT_VAL_FOR.TOCS)
		this.loadTocsErrorsFromCloudSQL(0, LIMIT_VAL_FOR.TOCS)
	}

	loadMoreTocs() {
		const offset = this.tocs.length
		this.loadTocsFromCloudSQL(offset, LIMIT_VAL_FOR.TOCS)
	}

	loadMoreTocsErrors() {
		const offset = this.tocsErrors.length
		this.loadTocsErrorsFromCloudSQL(offset, LIMIT_VAL_FOR.TOCS)
	}

	isExceptionTreated(tocId: string): boolean {
		return this.historyService.listExceptionsMarkAsTreated()
			.find(e => e == tocId) != undefined
	}
}
