import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { HistoryEntry } from 'src/app/model/history-entry.model';

@Component({
    selector: 'app-db-logs',
    templateUrl: './db-logs.component.html',
    styleUrls: ['./db-logs.component.scss']
})
export class DbLogsComponent implements OnInit {
    history: HistoryEntry[] = []

    constructor(private activatedRoute: ActivatedRoute) {

    }

    ngOnInit() {
        this.activatedRoute.data
            .subscribe(
                (data: { history: any }) => {
                    if (data.history != null) {
                        this.history = data.history.map((d: any) => new HistoryEntry(d))
                    }
                },
                (err: any) => { console.log("error", err) }
            )
    }

}
