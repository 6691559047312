import { ModelParent } from "./model-parent"

export class TocLog extends ModelParent {
    id: string
    cis: string
    date: number
    name: string
    step: string
    nb_tocs: number
    source: string
    success: boolean
    error: string
    source_url: string
    source_doctype: string
    source_page_exist: boolean


    constructor(data: any) {
        super()
        super.hydrate(this, data)
        this.setDefaultValues(data)
    }

    setDefaultValues(data: any) {
        if (!data.hasOwnProperty("success")) {
            this.success = false
        }

        if (!data.hasOwnProperty("source")) {
            this.source = "-"
        }

        if (!data.hasOwnProperty("nb_tocs")) {
            this.nb_tocs = 0
        }

        if (!data.hasOwnProperty("error")) {
            this.error = ""
        }

        if (!data.hasOwnProperty("source_url")) {
            this.source_url = "-"
        }

        if (!data.hasOwnProperty("source_doctype")) {
            this.source_doctype = "-"
        }

        if (!data.hasOwnProperty("source_page_exist")) {
            this.source_page_exist = false
        }
    }

    asArray() {
        return [
            this.cis,
            this.date,
            this.error,
            this.name,
            this.source_doctype,
            this.source_page_exist,
            this.source_url,
            this.step,
            this.success
        ]
    }

    fireError(): boolean {
        return !this.success && this.error != ""
    }

    processDone(): boolean {
        return this.fireError() || this.success
    }
}
