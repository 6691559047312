import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { AuthService } from 'src/app/services/auth.service';


@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  loginForm = new FormGroup({
    email: new FormControl('', [
      Validators.required,
      Validators.email
    ]),
    password: new FormControl('', [
      Validators.required
    ])
  })

  displayLoginError: boolean
  loading: boolean

  constructor(private authService: AuthService) {
    this.displayLoginError = false
    this.loading = false
  }

  ngOnInit() {

  }

  get email() {
    return this.loginForm.get('email');
  }

  get password() {
    return this.loginForm.get('password');
  }

  login() {
    this.displayLoginError = false
    this.loading = true

    this.authService.login(this.email.value, this.password.value)
      .then(() => this.loading = false)
      .catch(e => {
        this.loading = false
        this.displayLoginError = true
      })
  }
}
