import { ModelParent } from "./model-parent";
import { environment } from 'src/environments/environment';

export class Tool extends ModelParent{
    id: number
    name: string
    picture: string
    nb_rules: number
    source: string

    constructor(data: any) {
        super()
        super.hydrate(this, data)
    }

    picturePath() {
        let begin = 0
        let end = 299;

        while (true) {
            if (this.id >= begin && this.id <= end) {
                break
            }
            begin = end + 1;
            end = begin + 299
        }

        return `${environment.sfServer}/assets/uploads/app/dir${begin}-${end}/${this.id}/${this.picture}`  
    }

    getToolsIcon() {
        if (this.picture.includes('med-cdn')) {
            return this.picture;
        } else {
            return this.picturePath()
        }
    }
}