import { ModelParent } from "./model-parent"
import {DoctypeChart, DrugsStatusChart, GroupByErrorChart, TocsCountChart, TocsErrorChart} from './model';

export class ChartData extends ModelParent {
    doctype_chart: DoctypeChart[]
    tocs_explicit_error_chart: GroupByErrorChart[]
    drugs_status_chart: DrugsStatusChart
    tocs_count_chart: TocsCountChart[]
    tocs_error_chart: TocsErrorChart[]

    constructor(data: any) {
        super()
        super.hydrate(this, data)
    }
}
