<div id="admin-panel">
    <h2 class="{{ selectedDatabase }}">Admin Panel</h2>
    <h4>{{ selectedDatabase === null ? 'Aucune base selectionnée' : 'Base: ' + selectedDatabase }}</h4>
    <form>
        <mat-form-field appearance="fill" class="form-field">
            <mat-label>Choississez la base</mat-label>
            <mat-select [required]="true" (selectionChange)="updateDb($event)">
                <mat-option [value]="db" *ngFor="let db of databases">{{ db }}</mat-option>
            </mat-select>
            <!-- <mat-hint align="end">Choose database you wish to updated</mat-hint> -->
        </mat-form-field>

        <ng-container *ngIf="selectedDatabase !== null">
            <div class="action-buttons">
                <button mat-raised-button class="primary updateBtn" (click)="openDialog()"><i
                        class="fal fa-sync-alt"></i> Mettre à jour tous les médicaments</button>
                <button mat-raised-button class="updateBtn" (click)="$event.preventDefault(); showDrugsInput = !showDrugsInput"><i
                    class="fal fa-eye"></i> Mettre à jour des médicaments spécifiques</button>
                </div>

            <mat-card class="form-group" *ngIf="showDrugsInput">
                <mat-card-header>
                    <mat-card-subtitle>Entrez la liste des médicaments que vous souhaitez mettre à jour</mat-card-subtitle>
                </mat-card-header>
                <mat-card-content>
                    <mat-form-field appearance="fill" class="form-field">
                        <mat-label>Médicament(s)</mat-label>
                        <input matInput #drugs maxlength="256" autocomplete="off" placeholder="cis, cis, cis ....">
                        <mat-hint align="end">{{ drugs.value == "" ? 0 : drugs.value.split(",").length }} médicament(s)</mat-hint>
                    </mat-form-field>
                </mat-card-content>
                <mat-card-actions>
                    <button mat-raised-button (click)="$event.preventDefault(); updateDrugs(drugs.value)"> Valider </button>
                </mat-card-actions>
            </mat-card>
        </ng-container>
    </form>
</div>
