export class ModelParent {

    constructor() {

    }


    hydrate(object: any, data) {
        for (let prop of Object.getOwnPropertyNames(data)) {
            object[prop] = data[prop]
        }
    }

    time(date: string) {
        const splittedDate = date.split(" ")
        return {
            date: splittedDate[0],
            time: splittedDate[1]
        }
    }
}