import { Injectable } from '@angular/core';
import { ApiService } from './http/api.service';

@Injectable({
    providedIn: 'root'
})
export class AdminService {
    constructor(private api: ApiService) {
    }

    update(database: string, drugs: string[] = [], fromScratch: boolean = false, logHistoryID: string = null) {
        const params = {
            database: database,
            medics_ids: drugs,
            log_history_id: logHistoryID,
            from_scratch: fromScratch,
        }

        return this.api.post('/medic-db/buildDrugs', params)
    }
}
