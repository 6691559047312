<li [ngClass]="{'success': drug.success, 'error': drug.fireError()}">
    <div class="fields">
        <span>{{ index + 1 }}</span>
        <span>{{ drug.date | date:'yyyy/MM/dd'}}</span>
        <span>{{ drug.date | date:'HH:mm:ss z' }}</span>
        <span>{{ drug.cis }}</span>
        <span class="name" title="{{ drug.name }}">{{ drug.name }}</span>
        <span class="step" title="{{ drug.step }}">{{ drug.step }}</span>
    </div>
    <div class="error" *ngIf="drug.fireError()">
        <span class="span-error" title="{{ drug.error }}">
            <i class="fal fa-spider-black-widow"></i> Error: {{ drug.error }}
        </span>
    </div>
</li>
