<h2 mat-dialog-title><span><img src="{{ tool.getToolsIcon() }}"
                                alt="{{ tool.name }}"></span><span>{{ tool.name }}</span>
</h2>
<mat-dialog-content class="mat-typography">
    <div id="size"></div>
    <form [formGroup]="form">
        <div class="wrapper">
            <div [formGroup]="keywordG" class="item" *ngFor="let keywordG of keywords.controls; let i = index;">
                <div class="inputs">
                    <div>
                        <mat-form-field class="mat-form-field-full" appearance="outline">
                            <mat-label>Titre</mat-label>
                            <input matInput formControlName="title" autocomplete="off">
                        </mat-form-field>
                    </div>
                    <div>
                        <mat-form-field class="mat-form-field-full" appearance="outline">
                            <mat-label>https://url-vers-la-page.org</mat-label>
                            <input matInput formControlName="route" autocomplete="off" required>
                            <mat-hint>L'url de la page de redirection (obligatoireq)</mat-hint>
                        </mat-form-field>
                    </div>
                    <div>
                        <mat-form-field class="mat-form-field-full" appearance="outline">
                            <mat-label>Description</mat-label>
                            <input matInput formControlName="description" autocomplete="off">
                        </mat-form-field>
                    </div>
                    <div>
                        <mat-form-field class="mat-form-field-full" appearance="outline">
                            <mat-label>Mot clés</mat-label>
                            <input matInput formControlName="keywords" placeholder="word1, word2" autocomplete="off">
                            <mat-hint>Séparez les mot clés par une virgule (,)</mat-hint>
                        </mat-form-field>
                    </div>
                </div>
                <button mat-raised-button color="warn" class="del-btn" (click)="removeItem(i)">
                    <i class="fal fa-trash"></i>
                </button>
            </div>
        </div>

        <button mat-raised-button class="new" (click)="addItem()">Ajouter</button>
    </form>
</mat-dialog-content>
<p class="danger" *ngIf="!form.valid">Les champs URLs sont obligatoires veuillez tous les renseigner avant de soumettre
</p>
<mat-dialog-actions align="end">
    <button mat-button mat-dialog-close>Annuler</button>
    <!--<button (click)="save()" mat-button [mat-dialog-close]="true" cdkFocusInitial>Enregistrer</button>-->
    <button (click)="save()" mat-button cdkFocusInitial [disabled]="!form.valid">Enregistrer</button>
</mat-dialog-actions>