<li [ngClass]="{'success': toc.success, 'error': toc.fireError() && !markExceptionAsTreated, 'exception-treated': markExceptionAsTreated }">
    <div class="fields">
        <span>{{ index + 1 }}</span>
        <span>{{ toc.date | date:'yyyy/MM/dd'}}</span>
        <span>{{ toc.date | date:'HH:mm:ss z' }}</span>
        <span>{{ toc.nb_tocs }}</span>
        <span>{{ toc.cis }}</span>
        <span>{{ toc.source_doctype }}</span>
        <span [ngClass]="{'exist': toc.source_page_exist}">
            <a href="{{ toc.source_url }}" target="blank" title="{{ toc.source_url }}">
                <i class="far fa-external-link"></i> {{ toc.source_url }}
            </a>
        </span>
        <span class="name" title="{{ toc.name }}">{{ toc.name }}</span>
        <span class="source" title="{{ toc.source }}">
            <a href="{{ toc.source }}" target="blank">
                <i class="far fa-external-link"></i> {{ toc.source }}
            </a>
        </span>
        <span class="step" title="{{ toc.step }}">{{ toc.step }}</span>
    </div>
    <div class="error" *ngIf="toc.fireError()">
        <span class="span-error" title="{{ toc.error }}">
            <i class="fal fa-spider-black-widow"></i> Error: {{ toc.error }}
        </span>
        <button (click)="openDialog()"><i class="far fa-exclamation-circle"></i></button>
    </div>
</li>
